import { ACCESSIBILITY_MODE, MANDATORY_CLASS } from './constants';
import { isNilOrBlank } from './utils';
import type { DocumentSettings, OnlineMag } from './@types/pagetiger';

const getPopUpStyles = (styles: DocumentSettings): string => {
  if (Object.keys(styles).length === 0) {
    return '';
  }
  
  const {
    popupBackgroundImageUrl,
    popupBackgroundLogoUrl,
    popupBodyBGColour,
    popupBodyFont,
    popupBodyColour,
    popupButtonBGColour,
    popupButtonTextColour,
    popupHeadingColour,
    popupHeadingFont,
    popupHorizontalLineColour,
    popupHorizontalLineWidth,
    popupLinkColour,
    popupTableBGColour,
    popupTableBorderColour,
    popupTableHeadingBGColour,
    popupTableHeadingColour,
    popupBodyFontBoldVariant,
    popupBodyFontItalicVariant,
    popupBodyFontBoldItalicVariant
  } = styles;
  
  /** Colours For Competition */
  const progressBarColour = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `background-color: ${popupButtonBGColour};`;

  const compButtonSecondaryBorder = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `border: 2px solid ${popupButtonBGColour};`;

  const compButtonSecondaryColour = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `color: ${popupButtonBGColour};`;

  const compButtonSecondaryBoxShadow = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `box-shadow: 0 0 0 2px ${popupButtonBGColour};`;

  const compButtonBackground = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `background-color: ${popupButtonBGColour};`;

  const compButtonColour = isNilOrBlank(popupButtonTextColour)
    ? ``
    : `color: ${popupButtonTextColour};`;

  const compRadioShadow = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `box-shadow: inset 0 0 0 2px ${popupButtonBGColour};`;

  const compRadioShadowChecked = isNilOrBlank(popupButtonBGColour) && isNilOrBlank(popupButtonTextColour)
    ? ``
    : `box-shadow: inset 0 0 0 7px ${popupButtonBGColour}; background-color: ${popupButtonTextColour};`;

  const loadingBorder = isNilOrBlank(popupBodyColour)
    ? `border: 8px solid #000;`
    : `border: 8px solid ${popupBodyColour};`;

  const loadingBorderColour = isNilOrBlank(popupBodyColour)
    ? `border-color: #000 transparent transparent;`
    : `border-color: ${popupBodyColour} transparent transparent;`;

  const formElementBorderColour = isNilOrBlank(popupBodyColour)
    ? ``
    : `border-color: ${popupBodyColour};`;

  const questionLabelBorderColour = isNilOrBlank(popupBodyColour)
    ? ``
    : `border-color: ${popupBodyColour};`;

  const compSvgFill = isNilOrBlank(popupButtonBGColour) ? `` : `fill: ${popupButtonBGColour};`;

  const compSelectedAnswerBorder = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `border-color: ${popupButtonBGColour};`;

  const inputBorderColourProp = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `border-color: ${popupButtonBGColour};`;

  const compSelectedAnswerReview =
    isNilOrBlank(popupButtonBGColour) && isNilOrBlank(popupButtonTextColour)
      ? ``
      : `border-color: ${popupButtonBGColour}; background-color: ${popupButtonBGColour}; box-shadow: 0 0 0 2px ${popupButtonBGColour}; color: ${popupButtonTextColour}`;
  /** END colours for competition */

  const backgroundLogo =
    popupBackgroundLogoUrl.length > 0
      ? `.ptiArticle div#ptibox_div_inner { background-image: url(${popupBackgroundLogoUrl}); }`
      : ``;

  const backgroundImage =
    popupBackgroundImageUrl.length > 0
      ? `.ptiArticle div#ptibox_content { background-image: url(${popupBackgroundImageUrl}); }`
      : ``;

  const popupBackgroundColourProp = isNilOrBlank(popupBodyBGColour)
    ? `background-color: #fff;`
    : `background-color: ${popupBodyBGColour};`;
  const popupBodyColourProp = isNilOrBlank(popupBodyColour) ? `` : `color: ${popupBodyColour};`;
  const popupBodyFontProp = isNilOrBlank(popupBodyFont) ? `` : `font-family: ${popupBodyFont};`;
  const popupBodyFontBoldProp = isNilOrBlank(popupBodyFontBoldVariant) ? `` : `strong { font-family:${popupBodyFontBoldVariant}; }`;
  const popupBodyFontBoldItalicProp = isNilOrBlank(popupBodyFontBoldItalicVariant) ? `` : `strong em { font-family:${popupBodyFontBoldItalicVariant}; } em strong { font-family:${popupBodyFontBoldItalicVariant}; }`;
  const popupBodyFontItalicProp = isNilOrBlank(popupBodyFontItalicVariant) ? `` : `em { font-family:${popupBodyFontItalicVariant}; }`;
  const popupLinkColourProp = isNilOrBlank(popupLinkColour)
    ? `color: #026abc;`
    : `color: ${popupLinkColour};`;
  const popupOutlineColourProp = isNilOrBlank(popupLinkColour)
    ? ``
    : `box-shadow: 0 0 0 4px ${popupLinkColour};`;
  const popupHeadingColourProp = isNilOrBlank(popupHeadingColour)
    ? ``
    : `color: ${popupHeadingColour};`;
  const popupHeadingFontProp = isNilOrBlank(popupHeadingFont)
    ? ``
    : `font-family: ${popupHeadingFont};`;
  const popupButtonBGColourProp = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `background-color: ${popupButtonBGColour};`;
  const popupButtonFocusProp = isNilOrBlank(popupLinkColour)
    ? ``
    : `box-shadow: 0 0 0 3px ${popupButtonBGColour};`;
  const popupTableHeadingBGColourProp = isNilOrBlank(popupTableHeadingBGColour)
    ? ``
    : `background-color: ${popupTableHeadingBGColour};`;
  const popupTableHeadingColourProp = isNilOrBlank(popupTableHeadingColour)
    ? ``
    : `color: ${popupTableHeadingColour};`;
  const popupHorizontalLineColourProp = isNilOrBlank(popupHorizontalLineColour)
    ? ``
    : `border-top-color: ${popupHorizontalLineColour};`;
  const popupHorizontalLineWidthProp = `width: ${popupHorizontalLineWidth};`;
  const popupTableBackgroundColourProp = isNilOrBlank(popupTableBGColour)
    ? ``
    : `background-color: ${popupTableBGColour};`;
  const popupTableBorderColourProp = isNilOrBlank(popupTableBorderColour)
    ? ``
    : `border-color: ${popupTableBorderColour};`;
  const popupInputBorderProp = isNilOrBlank(popupBodyColour)
    ? ``
    : `border-color: ${popupBodyColour};`;
  const popupButtonColorProp = isNilOrBlank(popupButtonTextColour)
    ? ``
    : `color: ${popupButtonTextColour} !important;`;
  const popupInputFocusProp = isNilOrBlank(popupHeadingColour)
    ? ``
    : `border-color: ${popupHeadingColour};`;
  const boxShadowFocusProp = isNilOrBlank(popupButtonBGColour)
    ? ``
    : `box-shadow: 0 0 0 3px ${popupButtonBGColour};`;


  const formLabelStyle = isNilOrBlank(popupBodyFontBoldVariant) ? `` : `.form-element-label-text { font-weight: normal; font-style: normal; font-family:${popupBodyFontBoldVariant}; }`;
  const formLabelHelperStyle = isNilOrBlank(popupBodyFont) ? `` : `.form-element-label-helper-text { font-family:${popupBodyFont}; }`;
  const compProgressTextStyle = isNilOrBlank(popupBodyFontBoldVariant) ? `` : `.comp-container .progress-text { font-weight: normal; font-style: normal; font-family:${popupBodyFontBoldVariant}; }`;
  const boldFontStyle = isNilOrBlank(popupBodyFontBoldVariant) ? `` : `font-weight: normal; font-style: normal; font-family:${popupBodyFontBoldVariant};`;
  return `
      .lightbox-inner, .lightbox-content { ${popupBodyColourProp} ${popupBodyFontProp} ${popupBackgroundColourProp} }
      .progress-bar-status { ${progressBarColour} }
      ${popupBodyFontBoldProp}
      ${popupBodyFontItalicProp}
      ${popupBodyFontBoldItalicProp}
      .form-element-label-helper-text { ${popupBodyFontProp}  }
      .lightbox-top-svg { ${compSvgFill} }
      .question-item-label, .comp-answer-list-answer, .gallery-item-detail { ${questionLabelBorderColour} }
      .comp-button.mod-primary { ${compButtonBackground} ${compButtonColour} ${boldFontStyle} }
      .comp-button.mod-secondary { ${compButtonSecondaryBorder} ${compButtonSecondaryColour} }
      .comp-button:focus, .comp-button:hover { ${compButtonSecondaryBoxShadow} }
      .question-item-label.mod-selected { ${compSelectedAnswerBorder} }
      .comp-answer-list-answer.mod-selected { ${compSelectedAnswerReview} }
      .comp-answer-review { background: #fff; }
      .comp-answer-list-answer.mod-choosen { background-color: ${popupButtonBGColour}; color: ${popupButtonTextColour}; border-color: ${popupButtonBGColour}; }

      ${formLabelStyle}      
      ${compProgressTextStyle}   
      ${formLabelHelperStyle}   

      .gallery-item-detail.mod-selected { ${boxShadowFocusProp} ${compSelectedAnswerBorder} }
      .gallery-item-detail .gallery-item-image::before { ${compRadioShadow} }
      .gallery-item-detail.mod-selected .gallery-item-image::before { ${compRadioShadowChecked} }
      .review-question .mod-selected .gallery-item-detail { ${compSelectedAnswerReview} }

      .question-item-label.mod-selected { ${boxShadowFocusProp} }
      .loading-ring div { ${loadingBorder} ${loadingBorderColour} }
      .form-element-radio-span::before { ${compRadioShadow} }
      .form-element-radio:checked + span::before { ${compRadioShadowChecked} }
      .form-element-button:focus, .form-element-button:hover { ${boxShadowFocusProp} }
      .form-element-input, .form-element-textarea { ${formElementBorderColour} }
      .form-element-input:focus, .form-element-textarea:focus, .form-element-input:active { ${boxShadowFocusProp} outline: none !important; ${inputBorderColourProp} }
      .lightbox-inner h1 span,
      .lightbox-inner h2 span,
      .lightbox-inner h3 span,
      .lightbox-inner h4 span,
      .lightbox-inner h5 span,
      .lightbox-inner h6 span {
        font-weight: bold;
      }
      .lightbox-inner h1, .lightbox-inner h2, .lightbox-inner h3, .lightbox-inner h4, .lightbox-inner h5, .lightbox-inner h6, .pticomp .pti_title, .ptipoll .pti_title { ${popupHeadingColourProp} ${boldFontStyle}; }
      .lightbox-button-bg, .ptiArticle a.Button, .form-element-button { ${popupButtonBGColourProp} }
      .lightbox-button-bg-span, .form-element-button { ${popupButtonColorProp} ${boldFontStyle}}
      .lightbox-button-bg:focus, .ptiArticle a.Button:focus, .form-element-button:focus { ${popupButtonFocusProp} }
      .popup.mod-more-info table, .popup.mod-comp table { ${popupTableBorderColourProp} ${popupTableBackgroundColourProp} }
      .popup.mod-more-info th, .popup.mod-comp th { ${popupTableHeadingBGColourProp} ${popupTableHeadingColourProp} }
      .popup.mod-more-info hr, .popup.mod-comp hr { ${popupHorizontalLineColourProp} ${popupHorizontalLineWidthProp} }
      .popup.mod-more-info a, .popup.mod-more-info a:focus, .popup.mod-more-info a:hover, .popup.mod-comp a, .popup.mod-comp a:focus, .popup.mod-comp a:hover, .contents-item-link-text, .search-link { ${popupLinkColourProp} }
      .contents-page-link:focus, .contents-page-item.mod-checkbox.mod-focus { ${popupOutlineColourProp} }
      .popup-email-input { ${popupBodyColourProp} ${popupInputBorderProp} }
      .popup-email-input:focus { ${popupInputFocusProp} }
      ${backgroundLogo}
      ${backgroundImage}`;
};

const getToolbarStyles = (pageTurn: OnlineMag, docSettings: DocumentSettings): string => {
  const {
    onlineMagSideNavUseToolbarColour,
    onlineMagSideNavColour,
    toolbarColour,
    onlineMagSideNavIconColour,
    toolbarIconColour,
    onlineMagSideNavUseToolbarIconColour
  } = pageTurn;
  const buttonBackgroundColour = onlineMagSideNavUseToolbarColour
    ? toolbarColour
    : onlineMagSideNavColour;
  const sideNavIconColour = onlineMagSideNavUseToolbarIconColour
    ? toolbarIconColour
    : onlineMagSideNavIconColour;
  const toolBarIconColour = toolbarIconColour ? toolbarIconColour.toLowerCase() : '#fff';

  return `
      .toolbar,
      .toolbar-popup {
        background-color: ${toolbarColour};
      }

      .toolbar-popup-arrow {
        border-color: ${toolbarColour};
      }

      .toolbar-button:focus,
      .toolbar-menu-button:focus,
      .toolbar-customer-logo-link:focus,
      .toolbar-popup-list-item-button:focus,
      .side-nav:focus {
        outline-color: ${toolBarIconColour};
      }

      .icontoolbar,
      .mobile-menu-button-svg {
        fill: ${toolBarIconColour};
      }

      .toolbar-menu-button-current-page,
      .toolbar-menu-button-total-pages,
      .toolbar-popup-list-item-button {
        color: ${toolBarIconColour};
      }

      .toolbar-popup-list-item-button:hover,
      .toolbar-popup-list-item-button:focus {
        background-color: ${toolBarIconColour};
        color: ${toolbarColour};
      }

      .side-nav {
        background-color: ${buttonBackgroundColour};
      }

      .side-nav-svg,
      .lightbox-top-svg {
        fill: ${sideNavIconColour};
      }

      ${getPopUpStyles(docSettings)}

      .keyboard-container,
      .hg-theme-default {
        background-color: ${toolbarColour} !important;
      }

      .hg-button {
        background-color: ${toolbarColour} !important;
        border-color: ${toolBarIconColour} !important;
      }

      .hg-button span {
        color: ${toolBarIconColour} !important;
      }`;
};

const getInteractivityStyles = (pageTurn: OnlineMag, modules: any[]): string => {
  const {
    config,
    onlineMagClickableAreaColour: interactivityColour,
    onlineMagClickableAreaStyle,
    onlineMagMandatoryClickableAreaColour: interactivityMandatoryColour
  } = pageTurn;
  const hex00PercentAlpha = '00';
  const hex30PercentAlpha = '4D';
  const hex60PercentAlpha = '99';
  const isConstant = onlineMagClickableAreaStyle === 'constant';
  const isOnHover = onlineMagClickableAreaStyle === 'hover';
  const isNoGlow = onlineMagClickableAreaStyle === 'none';
  const isHtml = config.accessibleInterface === ACCESSIBILITY_MODE.HTML;
  const isHighContrast = config.accessibleInterface === ACCESSIBILITY_MODE.HIGH_CONTRAST;

  // Normal mode adjustments
  let activeOpacity = '0.01';
  let inactiveOpacity = '0.01';

  // HTML adjustments
  let inactiveOutline = hex00PercentAlpha;
  let activeOutline = hex00PercentAlpha;

  if (isOnHover || (isNoGlow && isHtml)) {
    activeOpacity = '0.6';
    activeOutline = hex60PercentAlpha;
  } else if (isConstant) {
    activeOpacity = '0.6';
    inactiveOpacity = '0.3';
    activeOutline = hex60PercentAlpha;
    inactiveOutline = hex30PercentAlpha;
  }

  if (isHighContrast) {
    activeOpacity = '1';
    inactiveOpacity = '1';
  }

  if (isHtml) {
    const customFocusOutlineStyles = modules.reduce((styles, m) => {
      /* Force the custom outline to be the focusOutlineColour */
      const constantOutlineColour =
        isConstant && m.focusOutlineColour
          ? `#interactive-module-${m.moduleType}-${m.id} { outline-color: ${
              m.focusOutlineColour + inactiveOutline
            }; }`
          : ``;

      return `
        ${styles}
        ${constantOutlineColour}

        #interactive-module-${m.moduleType}-${m.id}.mod-glow,
        #interactive-module-${m.moduleType}-${m.id}:hover,
        #interactive-module-${m.moduleType}-${m.id}:focus {
          outline-color: ${m.focusOutlineColour + activeOutline};
        }`;
    }, '');

    return `
    ${customFocusOutlineStyles}

    .interactivity-item {
      outline-color: ${interactivityColour + inactiveOutline};
    }

    .interactivity-item.mod-glow,
    .interactivity-item:hover,
    .interactivity-item:focus-visible {
      outline-color: ${interactivityColour + activeOutline};
    }

    .interactivity-item.${MANDATORY_CLASS} {
      outline-color: ${interactivityMandatoryColour + inactiveOutline};
    }

    .interactivity-item.${MANDATORY_CLASS}.mod-glow,
    .interactivity-item.${MANDATORY_CLASS}:hover,
    .interactivity-item.${MANDATORY_CLASS}:focus-visible {
      outline-color: ${interactivityMandatoryColour + activeOutline};
    }`;
  }

  return `
    .interactivity-item {
      background-color: ${interactivityColour};
      opacity: ${inactiveOpacity};
    }

    .interactivity-item.${MANDATORY_CLASS} {
      background-color: ${interactivityMandatoryColour}
    }

    .interactivity-item.mod-glow,
    .interactivity-item:hover,
    .interactivity-item:focus-visible {
      opacity: ${activeOpacity};
    }
  `;
};

export { getToolbarStyles, getInteractivityStyles };
